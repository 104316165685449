import React from 'react'
import SectionTitle from '../Title'
import './style.scss'

// images
import portfolio1 from '../../images/studies/1.jpg'
import portfolio2 from '../../images/studies/2.jpg'
import portfolio3 from '../../images/studies/3.jpg'
import portfolio4 from '../../images/studies/4.jpg'
import portfolio5 from '../../images/studies/5.jpg'

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]
const Portfolio = ({ title, subTitle, className, fullWidth, portfolioItem }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                </div>
                <p className="textCenter" >Creative and bold. Collective and pragmatic. Ambitious and responsible. Our team works hard every day to unlock growth for the companies they support, deliver the best possible opportunities for our investors, create sustainable value for our shareholders, and champion a decarbonized and inclusive society. Our human-sized and tightly-knit teams remain faithful to what we are at heart – investors and partners fully committed to transforming our companies by working closely alongside entrepreneurs and management teams.</p>
            </div>

            {/* {!fullWidth ? (
                <div className="portfolioWrapper">
                    {portfolioItem.map((portfolio, i) => (
                        <div key={i} className="portfolioItem">
                            <img src={portfolio.images} alt="" />
                            <div className="portfolioContent">
                                <p>{portfolio.subtitle}</p>
                                <h3>{portfolio.title}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                    <div className="container gridPortfolio">
                        <div className="row">
                            {portfolioItem.map((portfolio, i) => (
                                <div key={i} className="col-lg-4 col-sm-6 col-12">
                                    <div key={i} className="portfolioItem">
                                        <img src={portfolio.images} alt="" />
                                        <div className="portfolioContent">
                                            <p>{portfolio.subtitle}</p>
                                            <h3>{portfolio.title}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )} */}
        </div>
    )
}
export default Portfolio