import React from "react";
import SectionTitle from '../Title'
import './style.scss'

const ServiceArea = ({ className, title, subTitle,services }) => {
    return (
        <div className={className}>
            <div className="container">
                
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    <p className="textCenter">The emirate, known as the “gateway to the world,” has thrived on its East-West links for many centuries and lies at the heart of the Asia, Africa and India triangle. Historically, its geographic location has facilitated easy connections, business networking and access to trade.
                    The emirate is also home to an exceptional and cosmopolitan talent pool, lured from across the globe by dynamic careers, a secure family-friendly environment, tax-free salaries and year-round sunshine.
                    For overseas companies looking to set up in Dubai, the emirate’s 22 specially designed free zones allow for out-of-the-box company formation, as well as full foreign ownership, zero import duties and 100 per cent repatriation of company profits
                    </p>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                <div className="serviceIcon">
                                    <i className={`${service.icon}`}></i>
                                </div>
                                <div className="serviceContent">
                                    <h3>{service.title}</h3>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
export default ServiceArea