import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import PricingTable from "../../../components/PricingTable";
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import About2 from '../../../components/About2'

// images
import breadcumb from '../../../images/breadcumb/6.webp'

import './style.scss'
import Headquarters from '../../../components/Offices/Headquarters'

const aboutText = [
    { text: 'Our success is underpinned by our teams based in Paris, London and Luxembourg and our global network of advisors, along with our offices in Madrid, Frankfurt and New York.' },
 
]

const services = [
    {
        icon: 'fa fa-globe',
        title: 'Highly diversified economy',

    },
    {
        icon: 'fa fa-line-chart',
        title: 'Sizeable Annual Trade Surplus',
       
    },
    {
        icon: 'fa fa-road',
        title: 'Hyper-modern infrastructure',
       
    },
    {
        icon: 'fa fa-subway',
        title: 'Superior transportation',
        
    },
    {
        icon: 'fa fa-bar-chart',
        title: 'A world-class financial center',
       
    },
    {
        icon: 'fa fa-money',
        title: 'A zero-tax regime',
      
    },
    {
        icon: 'fa fa-building',
        title: 'Easy business setup',
      
    },
    {
        icon: 'fa fa-users',
        title: 'Access to 300,000 people',
        
    },
    {
        icon: 'fa fa-shield',
        title: 'Stable and secure governance',
       
    },
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Our Offices'}
]

const PracticePage = () => {
    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth'
              }
        );
    },[]);

    
    return (
        <Fragment>
            <header className="headerArea">
              
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Our Offices"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
           

            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleThree bgFFF"
                title="Why Dubai ?"
                subTitle="Headquarters "
                services={services}
            />
            
            <FooterArea/>
        </Fragment>
    )
}
export default PracticePage