import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo/zsz.png'
import './style.scss'
const footerLinks = [
    {
        title: 'Quick Link', menus: [
            { name: 'Home', route: '/' },
            { name: 'About', route: '/about' },
            { name: 'Our Offices', route: '/offices' },
            { name: 'Our Services', route: '/services' },
            { name: 'Contact', route: '/contact' },
        ]
    },
    
    {
        title: 'Contact Us', menus: [
            { name: 'Head Office Address' },
            { name: 'P.O.Box: 214930 DUBAI, ', },
            { name: ' 201 Cayan Tower, TECOM, UAE', },
            { name: ' +971 4 451 9992 ', },
            { name: 'contact@zszdeveloper.com', },
        ]
    },
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img className="logoFooter" src={logo} alt="" />
                                </Link>
                                <p>ZSZ Developer Limited, is a private limited company headquartered in Dubai, United Arab Emirates. The company was founded in 2008 and has strived ever since to stand alongside creative innovators. </p>
                            </div>
                        </div>
                        {footerLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>  © 2021 ZSZ Developer Limited. All Rights Reserved</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                {/* <ul className="socialListFooter">
                    
                                    <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea