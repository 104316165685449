import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'

// images
import testmonial from '../../images/testimonial/3.webp'
import testmonial2 from '../../images/testimonial/2.png'

const sliders = [
    {
        text: 'The insights' ,
        text2: 'of our committed experts',
        
     
    
    },
    {
        text: 'In-depth ',
        text2: 'industry knowledge',
      
       
    },
    {
        text: 'Prime access to global markets ',
        text2: 'and a responsible approach to value creation',
       
    }
]
// {
//     text: 'In-depth industry knowledge',
//     images: testmonial2,
//     title: 'Jhony Goaver',
//     subTitle: 'CEO of American BDS'
// }
const settings = {
   
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
};
const Testmonial = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={testmonial} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>We offer </span>
                            <h2>Our Services</h2>
                           
                            <p>We specialize in three asset classes in which we have developed substantial expertise, helping finance businesses right across the investment spectrum. We invest for both ourselves and institutional clients. We apply our expertise across all expanding sectors, including Tech, Tourism and Financial Services. As a committed investor, we can help companies achieve virtuous and responsible growth.
                                We offer everything our portfolio companies need to grow: 
                            </p>
                            <br/>
                            <Slider className="testmonialSlider" {...settings}>
                                {sliders.map((slider, i) => (
                                    <div key={i} className="slideItem">
                                        <h3>{slider.text}</h3>
                                        <h3>{slider.text2}</h3>
                                        <div className="thumbWrap">
                                            <div className="thumbImg">
                                                <img src={slider.images} alt="" />
                                            </div>
                                            {/* <div className="imgContent">
                                                <h4>{slider.title}</h4>
                                                <span>{slider.subTitle}</span>
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial