import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import About2 from '../../../components/About2'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Portfolio from '../../../components/Portfolio'
// images
import about from '../../../images/about/5.webp'
import about2 from '../../../images/about/6.webp'
import breadcumb from '../../../images/breadcumb/10.webp'

import portfolio1 from '../../../images/studies/100.webp'
import portfolio2 from '../../../images/studies/200.webp'
import portfolio3 from '../../../images/studies/300.webp'
import portfolio4 from '../../../images/studies/400.webp'
import portfolio5 from '../../../images/studies/500.webp'

import './style.scss'

const aboutText = [
    { text: 'We are investors who actively seek to successfully transform our portfolio businesses and accelerate their pace of change in the interests of all our stakeholders.' },
    { text: 'We spot emerging businesses, fund them, accelerate their development and unlock their value as they move along the path from a disruptive project to a small business, a medium-sized company and, ultimately, an international group. We stand alongside creative innovators. We support senior managers and investors. We provide expert support at the right time. And we invest for the long term.' },
    { text: 'We have a longstanding presence in UAE and in EUROPE and we have global aspirations, and are looking at expanding into Africa and Asia in the next two years.' },
]
const aboutText2 = [
    { text: 'Need a robust, bold, visionary, exemplary, and engaged partner?' },
    { text: 'ZSZ is that partner. We spot emerging businesses, fund them, accelerate their development and unlock their value as they move along the path from disruptive project to small business, medium-sized company and, ultimately, international group. We stand alongside creative innovators. We support senior managers and investors. We provide expert support at the right time. And we invest for the long term.' },
]

const portfolioItem = [
    { images: portfolio1, title: 'Creative and bold', subtitle: 'Our team is' },
    { images: portfolio2, title: 'Collective and pragmatic ', subtitle: 'Our team is' },
    { images: portfolio3, title: 'Ambitious and responsible', subtitle: 'Our team is' },
    { images: portfolio4, title: 'Human-sized and tightly-knit', subtitle: 'Our team is' },
    { images: portfolio5, title: 'Authentic and Fully Committed ', subtitle: 'Our team is' }
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]



const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutPage = () => {
    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                
                behavior: 'smooth'
              }
        );
    },[]);
    
    return (
        <Fragment>
            <header className="headerArea">
               
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
         
            <About
                className="aboutArea "
                title="Who We Are"
                subTitle="Introduction"
                images={about}
                orderLast="order-last"
                more = {false}
                pragraphs={aboutText}
            />
             <About2
                className="aboutArea "
                title="Vision"
                subTitle="Our"
                images={about2}
                orderLast="order-last"
                
                pragraphs={aboutText2}
            />
            <Portfolio
                className="portfolioArea"
                title="Our Team"
              
                portfolioItem={portfolioItem}
            />
           
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage