import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import CetagorySidebar from '../../../components/CetagorySidebar'
import FooterArea from '../../../components/FooterArea'
import BannerSidebar from '../../../components/BannerSidebar'
import SingleContentArea from '../../../components/SingleContentArea'

import Testmonial from "../../../components/Testmonial";

// images
import breadcumb from '../../../images/breadcumb/1.jpg'
import banner from '../../../images/practice/2.jpg'
import single from '../../../images/practice/3.jpg'

import './style.scss'


const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Practice', route: '/practice' },
    { name: 'Practice area details' }
]



const PracticeSinglePage = () => {
    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth'
              }
        );
    },[]);
    return (
        <Fragment>
            <header className="headerArea">
              
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Practice Single Area"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Testmonial
                className="testmonialArea"
            />
        </Fragment>
    )
}
export default PracticeSinglePage