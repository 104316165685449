
import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images

import about from '../../../images/about/4.webp'
import signature from '../../../images/about/1.png'

// images

import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'

const aboutText = [
    { text: 'We are investors who actively seek to successfully transform our portfolio businesses and accelerate their pace of change in the interests of all our stakeholders.' },
    { text: 'We spot emerging businesses, fund them, accelerate their development and unlock their value as they move along the path from a disruptive project to a small business, a medium-sized company and, ultimately, an international group. We stand alongside creative innovators. We support senior managers and investors. We provide expert support at the right time. And we invest for the long term.' },
     {text : 'We have a longstanding presence in UAE and in EUROPE and we have global aspirations, and are looking at expanding into Africa and Asia in the next two years.'}
]



const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'The Best Business Support',
        // subTitle: 'As Like A Friend.',
        // text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'We Deliver Custom Investing Strategies',
        // subTitle: 'As Like A Friend.',
        // text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
   
    {
        images: 'slideWrapperThree',
        title: 'We Support Your Financial Interest',
        // subTitle: 'As Like A Friend.',
        // text: 'The Most Talented Law Frim',
        button: 'Contact us now'
    },
    
   
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const HomePageOne = () => {
    useEffect(() => {
        window.scrollTo(
            {
                top: 0,
                behavior: 'smooth'
              }
        );
    },[]);
    
    return (
        <Fragment>
            <header className="headerArea">
              
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
        
            <About
                className="aboutArea"
                title="About Us"
                images={about}             
                pragraphs={aboutText}
                more = {true}
            />
            
            
            
           <div id="#contact">

            <FooterArea />
           </div>
        </Fragment>
    )
}
export default HomePageOne