import React, { Fragment, useEffect } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/breadcumb/9.webp'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {
    useEffect(() => {
        window.scrollTo(
            {
                top: 400,
                behavior: 'smooth'
              }
        );
    },[]);
    
    return (
        <Fragment>
            <header className="headerArea">
               
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contact"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h3>Contact Details</h3>
                                <p>A devoted customer service team is at your disposal to help you with any question or need you might have. You can always reach us through the following contact details.
                                </p>
                                <h4>Address</h4>
                                <p>201 Cayan Tower, TECOM, <br/> P.O.Box: 214930 DUBAI UAE</p>
                                <h4>Phone</h4>
                                <p>+971 4 451 9992 </p>
                               
                                <h4>Email</h4>
                                <p>contact@zszdeveloper.com</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactUSForm">
                                <h3>Quick Contact Form</h3>
                                <Form
                                    addressInfo={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

           
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage